@charset "utf-8";/* CSS Document */
*{margin: 0;padding: 0;}
html {font-size: 62.5%;height: 100%}
body {margin: 0 auto;font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;height: 100%;overflow-x: hidden;max-width: 1920px;}
img {border: none;}
ul li {list-style-type: none;}
ul, form, p, a, img, table, tr, td, li, dd, dt, dl, span {margin: 0;padding: 0;list-style: none;color: #333;}
a {text-decoration: none;color: #333;outline: none;transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;}
h1 {margin: 0;padding: 0;font-weight: normal;color: #333;}
.clear {clear: both;}
.maln {margin-left: auto !important}
input, textarea {font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;color:#333;border: none;outline: none;}
.l{ float:left;}
.r{ float:right;}
.fix{*zoom:1; } .fix:after,.fix:before{display:block; content:"clear"; height:0; clear:both; overflow:hidden; visibility:hidden; }

::-webkit-input-placeholder {/* WebKit browsers */
 color:#1b1b1b;}
:-o-placeholder {/* Mozilla Firefox 4 to 18 */
 color:#1b1b1b;}
::-moz-placeholder {/* Mozilla Firefox 19+ */
 color:#1b1b1b; opacity: 1;}
:-ms-input-placeholder {/* Internet Explorer 10+ */
 color:#1b1b1b;}
 
.wp {width: 1200px;margin: auto;}
@media screen and (min-width:1220px) {.wp {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp {width: 95%;margin: auto;}}

.no_margin{margin-left: auto\0!important;}
@media screen and (min-width:1220px) {.no_margin{margin-left: auto!important;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.no_margin{margin-left: auto!important;}}

/*.container*/
.wap-hd-nav { display: none; }

.wap-nav { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; -o-transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; }

.wap-nav .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.wap-nav .nav span { font-family: arial, helvetica, sans-serif; }

.wap-nav .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; }

.wap-nav .nav form .iptc { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.wap-nav .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: #fff url("../images/sousuo.png") no-repeat center center; }

.wap-nav .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top: 45px; -ms-box-sizing: border-box; -o-box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.wap-nav .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.wap-nav .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("../images/shizi.png") no-repeat center center; }

.wap-nav .addition.active { background-image: url("../images/yizi.png"); }

.wap-nav .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.wap-nav .nav > ul > li > a { margin-right: 40px; color: #fff; font-size: 12px; font-size: 1.2rem; display: block; }

.wap-nav .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.wap-nav .nav > ul > li .c-show { display: none; width: 100%; }

.wap-nav .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a { position: relative; margin-right: 40px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { margin-right: 40px; text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { margin-right: 40px; text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > dl { padding: 20px; }

.wap-nav .nav > ul > dl dd { float: left; margin: 0 2px; }

.wap-nav .menubtn { width: 23px; height: 18px; position: absolute; top: 12px; right: 12px; -webkit-transition: all 0.4s ease 0s; -o-transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; }

.wap-nav .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #0084ff; }

@media screen and (min-width: 768px) { .wap-nav, .menubtn { display: none; } }

@media screen and (max-width: 991px) { .wap-hd-nav { display: -webkit-box; display: -webkit-flex; display: -moz-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -webkit-flex-flow: row nowrap; -moz-box-orient: horizontal; -moz-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; -webkit-box-pack: justify; -webkit-justify-content: space-between; -moz-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; background-color: #0084ff; }
  .wap-hd-nav-item { width: 25%; }
  .wap-hd-nav-item a { display: block; padding: 5px 0; line-height: 20px; font-size: 14px; color: white; text-align: center; background-color: #363636; }
  .wap-hd-nav-item a.active { color: white; background-color: #0084ff; } }


/***********  lunbo  *************/
.m-imgshow { overflow: hidden; }

.m-imgshow .box-wp { position: relative; margin: 0 auto; max-width: 1390; padding: 0; }

.m-imgshow .box-big { position: relative; margin: 0 auto;}

.m-imgshow .box-big div { position: relative; }

.m-imgshow .box-big .img { line-height: 0; font-size: 0; text-align: center; display: block;}

.m-imgshow .box-big .img img{max-width: 100%;}

.m-imgshow .box-big .title { display: block; background: #000000; background: rgba(0, 0, 0, 0.5); position: absolute; bottom: 13px; left: 13px; right: 13px; font-size: 22px; font-size: 2.2rem; text-align: center; color: #FFFFFF; line-height: 26px; padding: 14px 9px 14px; }

.m-imgshow .box-small { margin-left: auto; margin-right: auto; position: absolute; left: 0;width: 100%;bottom: 0;z-index: 99;}

.m-imgshow .box-btn { position: absolute; top: 50%; margin-top: -31.5px; display: block; text-align: center; }

.m-imgshow .box-btn.prev { left: 0; }

.m-imgshow .box-btn.next { right: 0; }

.m-imgshow .box-btn .iconfont { font-size: 63px; font-size: 6.3rem; color: #fff; }

.m-imgshow .box-btn .iconfont:hover { color: #efeded; }

.m-imgshow .box-list { padding-top: 0; overflow: hidden; }

.m-imgshow .box-list ul { width: 1000%; }

.m-imgshow .box-list ul li { float: left;width: 2%;}

.m-imgshow .list-item { position: relative; border: 3px solid #c9c8c8;}

.m-imgshow .list-item .iconfont { display: none; position: absolute; font-size: 18px; font-size: 1.8rem; color: #333; left: 50%; margin-left: -6px; top: -13px; }

.m-imgshow .list-item img { display: block; max-width: 100%;}

.m-imgshow .list-item.active { border: solid 3px #0084ff; position: relative; }

.m-imgshow .box-small .box-btn { display: none; }

@media screen and (max-width: 1219px) { 
	.m-imgshow .box-btn .iconfont{font-size: 46px;font-size: 4.6rem;}
}
@media screen and (max-width: 999px) {
	.m-imgshow .box-btn .iconfont{font-size: 30px;font-size: 3rem;}
  .m-imgshow .box-big .title { bottom: 8px; left: 8px; right: 8px; font-size: 16px; font-size: 1.6rem; }
  .m-imgshow .box-list ul li { padding-top: 20px; } }

@media screen and (max-width: 500px) { 
	.m-imgshow .box-btn .iconfont{font-size:22px;font-size:2.2rem;}
	.m-imgshow .box-btn{margin-top: -20px;}
}

/*****************  toplx  ****************/

.toplx { padding-bottom: 15px; background: #fff; }

.toplx ul { float: right;font-weight: bold; margin-top: 30px; }

.toplx p, .toplx a { color: #666666; }

.toplx ul li { display: inline-block; line-height: 30px; padding: 0 8px; }

.toplx a:hover { color: #0084ff; }

/***********  head  ************/
.head { background: #fff;box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);text-align: center;}

.logo { padding-top: 21px; }

.logo img { max-width: 100%; }

.navlist { padding-top: 15px; }

.navlist2 { display: none; }

.navlist > li { display: inline-block; position: relative; padding-bottom: 17px;}

.navlist > li > a {font-weight: bold; display: block; line-height: 18px;padding: 0 45px;border-left: 3px solid #e7e7e7;color: #666666;transition: 0.3s;font-size:14px;font-size:1.4rem;}

.navlist > li:first-child > a{border-left: none;}

.navlist > li:hover > a { color: #0084ff; font-size:18px;font-size:1.8rem;}

.navlist > li.currentnav > a { color: #0084ff; font-size:18px;font-size:1.8rem;}

.search { margin-top: 27px; position: relative; margin-right: 35px; }

.sou { width: 258px; height: 30px; line-height: 30px; padding-left: 40px; border-radius: 15px; color: #666666; background: #f5f5f5; border: 1px solid #e7e7e7;}

.suo { position: absolute; right: 0px; top: 0px; width: 77px; height: 32px; background: #808080; border-top-right-radius: 15px;border-bottom-right-radius: 15px;cursor: pointer; color: #fff;font-weight: bold;}

.search form i { position: absolute; left: 0px; top: 0px; width: 40px; height: 32px; line-height: 32px; text-align: center;color: #666666; border-radius: 5px; font-size:22px;font-size:2.2rem;}

@media screen and (max-width: 1219px) { 
	.navlist > li > a{padding: 0 30px;}
}
@media screen and (max-width: 999px) { 
	.navlist > li > a{padding: 0 15px;}
}
@media screen and (max-width: 800px) { 
	.navlist > li > a{padding: 0 12px;}
}
@media screen and (max-width: 767px) { .search { display: none; }
  .navlist1 { display: none; }
  .navlist2 { display: block; }
  .navlist > li { float: left; width: 25%; text-align: center; margin: 0; }
  .navlist > li:hover > a { font-size:14px;font-size:1.4rem;}
	.navlist > li.currentnav > a { font-size:14px;font-size:1.4rem;}
  .navlist > li > a{padding: 0;}
}
@media screen and (max-width: 500px) { 
	.navlist > li > a{border: none;}
}
@media screen and (max-width: 400px) { 
	.navlist > li > a{font-size: 12px;font-size: 1.2rem;}
	.navlist > li{padding-bottom: 10px;}
}

/**************   symain   ***************/
.symain{padding-top: 39px;padding-bottom: 101px;}

.ssbd{padding-top: 14px;}

.sszd{padding-top: 21px;}

.syzuo { width: 876px;}

.syyou { width: 285px;}

.syyou_wp { padding: 0 16px; padding-bottom: 20px; }

.sypbox {padding-bottom: 9px;border-bottom: 1px solid #e7e7e7;margin-bottom: 26px;}

.syptu { display: block; border: 1px solid #e7e7e7; margin-left: 75px; border-radius: 10px;overflow: hidden;margin-top: 9px;}

.syptu img { display: block; width: 100%; max-width: 158px; max-height: 158px; }

.sypp1 { display: block; font-size:16px;font-size:1.6rem; font-weight: bold; line-height: 24px; margin-bottom: 13px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.sypp2 { display: block; color: #666666; line-height: 20px; max-height: 60px; overflow: hidden; }

.sypp2 span{color: #0084ff;font-weight: bold;}

.sypp1:hover { color: #0084ff; }

.sypp2:hover { color: #0084ff; }

.sypbox:first-child { border-top: none; padding-top: 0; margin-top: 0; }

.item-box { margin-top: 46px; }

.item-box .item-info { float: left; font-size: 0; letter-spacing: 0; }

.item-box .item-info .item-pics { display: inline-block; vertical-align: middle; width: 34px; height: 34px; border-radius: 50%; margin-right: 10px; }

.item-box .item-info .item-pics img { display: block; width: 100%; height: 100%; }

.item-box .item-num { font-size: 0; letter-spacing: 0; float: left; line-height: 24px; margin-top: 5px; margin-right: 10px; }

.item-box .item-num .iconfont { font-size: 20px; font-size: 2rem; margin-right: 6px; vertical-align: middle; color: #999999; }

.item-box .item-num .iconfont.active { color: #0084ff; }

.item-box .item-num p { display: inline-block; vertical-align: middle; font-size: 12px; font-size: 1.2rem; margin-right: 35px; color: #999999;}

.item-box .item-num p, .item-box .item-num p a { color: #999999; }

.item-box .item-num .p3 { cursor: pointer; }

.item-box .item-num .p3 .iconfont { font-size: 18px; font-size: 1.8rem; }

.item-box .item-info .item-name { display: inline-block; vertical-align: middle; font-size:16px;font-size:1.6rem; line-height: 24px; width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.item-box .item-info .item-name a{color: #999999;}

.item-box .item-info .item-name a:hover { color: #0084ff; }

.sytu { margin-bottom: 30px; display: block; }

.sytu img { display: block; width: 100%; max-width: 881px; max-height: 260px; }

.slide-title { line-height: 24px;font-weight: bold; margin-bottom: 17px;}

.sybai3 .slide-title{margin-bottom: 10px;}

.slide-title a:hover { color: #0084ff; }

.sybai2{padding: 0 14px;padding-top: 16px;border-top: 3px solid #6baaff;margin-bottom: 41px;}

.sybai2 li a { display: block; color: #666666; line-height: 20px; padding-top: 15px; padding-bottom: 15px; overflow: hidden; overflow: hidden;}

.sybai5 li a{line-height: 22px;}

.sybai4 li i{float: right;font-size:14px;font-size:1.4rem;margin-top: 18px;color: #bcbcbc;}

.sybai2 li a:hover { color: #0084ff; }

.sybai5 li i{float: left;margin-right: 10px;margin-top: 16px;font-size:20px;font-size:2rem;}

.cedot {margin-top: 8px;}

.cedot .item-btn {display: block;color: #333333; width: 100%;box-sizing: border-box;border-radius: 5px;height: 36px;line-height: 34px;border: 1px solid #e7e7e7;background: #f5f5f5;text-align: center;font-weight: bold;}

.cedot .item-btn:hover { color: #0084ff; }

.slide-title2 { line-height: 22px;margin-bottom: 15px;}

.sybai3{position: relative;}

.baicover{position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: url(../images/baicover.png);text-align: center;}

.sybai3 form span { display: block; line-height: 30px; margin-bottom: 2px; margin-top: 10px; }

.sybai3 form span i{font-style: normal;color: #aaaaaa;}

.sybai3 form textarea { border: 1px solid #d9d9d9; padding-left: 10px; width: 233px; line-height: 30px; }

.sybai3 form .sytext1 { height: 70px; }

.sybai3 form .sytext2 { height: 158px; margin-bottom: 17px; }

.sybai3 form .syint1 { line-height: 30px; padding-left: 10px; width: 233px; border: 1px solid #d9d9d9; }

.sybai3 form .syint2 { line-height: 30px; padding-left: 10px; width: 160px; border: 1px solid #d9d9d9; float: left; }

.sybai3 form img { cursor: pointer; }

.sybai3 form .syint3 { line-height: 40px; width: 120px;background: #0084ff; color: #fff;cursor: pointer; margin: 0 auto;display: block;margin-top: 28px; }

.sybai3 form .syint3:hover { background: #037beb; }

.item-success { margin-top: 68%; }

.item-success .iconfont { width: 50px;height: 50px;display: block;border: 3px solid #0084ff;line-height: 50px;text-align: center;border-radius: 50%;font-size: 30px; font-size: 3rem;font-weight: bold;color: #0084ff; margin: 0 auto;}

.item-success .item-title { overflow: hidden; font-size:16px;font-size:1.6rem; line-height: 22px; letter-spacing: -0.2px; margin-top: 26px;}

@media screen and (max-width: 1219px) { .syzuo { width: 660px; }
  .sypp1 { font-size: 16px; font-size: 1.6rem; margin-bottom: 10px; } }

@media screen and (max-width: 999px) { .syzuo { width: 100%; }
  .syyou { width: 100%; margin-top: 20px;}
  .sybai3 form .syint1 { width: -moz-calc(100% - 10px); width: calc(100% - 10px); }
  .sybai3 form textarea { width: -moz-calc(100% - 10px); width: calc(100% - 10px); }
  .sybai3 form .syint2 { float: none; }
  .sybai3 form img { vertical-align: middle; } 
  .symain{padding-bottom: 20px;}
}

@media screen and (max-width: 767px) { .sypp1 { font-size: 14px; font-size: 1.4rem; } }

@media screen and (max-width: 550px) { .item-box .item-num p { margin-left: 20px; }
  .syptu { float: none; width: auto; max-width: 198px; margin: 0 auto; }
  .sypnr { padding-top: 10px; } }

@media screen and (max-width: 500px) { .item-box .item-info { float: none; }
  .item-box .item-num { float: none; }
  .item-box .item-num p.p1 { margin-left: 0; }
  .sybai { margin-bottom: 20px; } }


/***********  sytu  *************/
.slide { position: relative;margin-bottom: 24px;}

.slide .carouse { width: 100%; overflow: hidden; position: relative; }

.slide .carouse .slideItem { width: 100%; position: absolute; cursor: pointer; }

.slide .carouse .slideItem .banner-img { width: 100%;display: block; }

.slide .console { height: 57px; display: inline-block; width: 35px; position: absolute; top: 40%; }

.slide .dotList { position: absolute; width: 100%; bottom: 7px; text-align: center; }

.slide .dotList ul li { display: inline-block; width: 14px; height: 14px; background:#fff; margin: 0 8px; cursor: pointer; }

.slide .carousel-control { position: absolute; top: 50%; width: 60px; height: 60px; z-index: 999; margin-top: -30px;display: none;}

.slide .carousel-control.left { left: 8%; cursor: pointer; }

.slide .carousel-control.right { right: 8%; cursor: pointer; }

.slide .dotList ul li{cursor: pointer; background:#fff;  font-size: 0px; margin:0 5px; border: none;outline: none;width: 20px;height:10px;border-radius: 5px;}
.slide .dotList ul li.active{background:#333333;;}

.slide .slidedetail { display: none; }

@media screen and (max-width:767px) {
    .slide .dotList{bottom: 5px;}
}

/*************  bottom  ************/
.bottom{background: #222222;padding-top: 52px;padding-bottom: 53px;text-align: center;}
.bottom p,.bottom a,.bottom span{color: #eeeeee;}
.bottom a:hover{color: #0084ff;}
.div>a,.div>span{display: block;font-size:24px;font-size:2.4rem;font-weight: bold;color: #0084ff;margin-bottom: 9px;text-transform: uppercase;}

/*************  sousuobudao  **************/
.sshui{background: #f4f4f4;padding: 0 20px;padding-top: 234px;padding-bottom: 238px;text-align: center;}
.biaoqing{font-size:16px;font-size:1.6rem;}
.biaoqing i{font-size: 60px;font-size: 6rem;color: #0084ff;vertical-align: middle;margin-right: 20px;}
.back{display: inline-block;min-width: 120px;height: 40px;line-height: 40px;box-sizing: border-box;padding: 0 10px;background: #0084ff;color: #fff;margin-top: 33px;}

@media screen and (max-width:1219px) {
  .sshui{padding-top: 155px;padding-bottom: 155px;}
}
@media screen and (max-width:767px) {
  .biaoqing i{font-size: 50px;font-size: 5rem;}
  .sshui{padding-top: 120px;padding-bottom: 120px;}
}
@media screen and (max-width:500px) {
  .biaoqing{font-size:14px;font-size:1.4rem;}
  .biaoqing i{font-size: 40px;font-size: 4rem;margin-right: 7px;}
  .sshui{padding-top: 60px;padding-bottom: 60px;}
}

/************  bread  *************/
.mianbao{ font-size:12px; font-size:1.2rem; line-height:24px; color:#666666; padding-top:14px;}
.mianbao a{ color:#666666;}
.mianbao a:hover{ color:#333333;font-weight: bold;}
.co00{ color:#333333 !important;font-weight: bold;}


.page { font-size: 0; letter-spacing: 0; text-align: center; }

.page span, .page a, .page input { display: inline-block; vertical-align: top; font-size: 13px; font-size: 1.3rem; line-height: 30px; color: #666; margin-bottom: 10px; border-radius: 2px; }

.page input { width: 50px; height: 30px; background-color: #fff; border: solid 1px #D9D9D9; text-align: center; margin: 0 5px; border-radius: 2px; }

.page a { min-width: 30px; height: 30px; background-color: #fff; border: solid 1px #D9D9D9; text-align: center; margin: 0 5px; color: #333;  -moz-box-sizing: border-box; box-sizing: border-box;}

.page a .iconfont { color: #C4C4C4; font-size: 12px; font-size: 1.2rem; line-height: 28px; display: block; }

.page a.prev { margin-left: 15px; }

.page a.next { margin-right: 19px; }

.page a:hover, .page a.active { background-color: #0084ff; color: #fff; border-color: #0084ff; }

/**********  xiangqing  **********/
/*-------------5-blog-detail start---------------------------------------------------------*/
.blog-detail { background: #fff;}

.blog-detail  .item-title { text-align: center;font-size:16px;font-size:1.6rem; font-weight: bold; line-height: 30px; margin-bottom: 10px; }

@media screen and (max-width: 767px) { .blog-detail .item-title { margin-bottom: 15px; font-size: 18px; font-size: 1.8rem; } }

@media screen and (max-width: 640px) { .blog-detail .item-title { font-size: 18px; font-size: 1.8rem; } }

.blog-detail .item-cate { font-size: 0; letter-spacing: 0; text-align: center; }

.blog-detail .item-cate p { display: inline-block; vertical-align: middle; line-height: 24px; font-size: 14px; font-size: 1.4rem; }

.blog-detail .item-cate p, .blog-detail .item-cate p a { color: #999999; font-size: 12px;font-size: 1.2rem;}

.blog-detail .item-cate p a:hover { color: #0084ff; }

.blog-detail .item-cate .item-img { margin-right: 33px; }

.blog-detail .item-cate .item-img span { margin-right: 5px; }

.blog-detail .item-cate .item-img img { width: 30px; height: 30px; border-radius: 50%; vertical-align: middle; margin-right: 5px; }

@media screen and (max-width: 1219px) { .blog-detail .item-cate .item-img { margin-right: 30px; } }

@media screen and (max-width: 999px) { .blog-detail .item-cate .item-img { display: block; margin-bottom: 10px; margin-right: 0; }
  .blog-detail .item-cate .item-img img { width: 40px; height: 40px; } }

@media screen and (max-width: 767px) { .blog-detail .item-cate .item-img img { width: 35px; height: 35px; } }

.blog-detail .item-cate .item-date { margin-right: 40px; }

.blog-detail .item-cate .item-date i{font-size:14px;font-size:1.4rem;margin-right: 3px;}

@media screen and (max-width: 1219px) { .blog-detail .item-cate .item-date { margin-right: 30px; } }

@media screen and (max-width: 999px) { .blog-detail .item-cate .item-date { margin-right: 20px; } }

.blog-detail .item-num { font-size: 0; letter-spacing: 0;}

.blog-detail .item-num .iconfont { font-size: 20px; font-size: 2.0rem; margin-right: 3px; vertical-align: middle; color: #999999; }

.blog-detail .item-num .iconfont.active { color: #0084ff; }

.blog-detail .item-num span { display: inline-block; vertical-align: middle; font-size: 14px; font-size: 1.4rem; margin-left: 33px; color: #999999;}

.blog-detail .item-num span:first-child { margin-left: 0; }

.blog-detail .item-num .p3 { cursor: pointer; }

.blog-detail .item-num .p3 .iconfont { font-size: 18px; font-size: 1.8rem; }

@media screen and (max-width: 1219px) { .blog-detail .item-cate .item-num { margin-right: 30px; }
  .blog-detail .item-cate .item-num span { margin-left: 10px; } }

@media screen and (max-width: 999px) { .blog-detail .item-cate .item-num { margin-right: 20px; }
  .blog-detail .item-cate .item-num span { margin-left: 10px; } }

.blog-detail .item-body { letter-spacing: -0.1px; text-align: left; padding: 18px 0 29px; font-size: 14px; font-size: 1.4rem; line-height: 20px; border-bottom: 1px solid #e7e7e7;margin-bottom: 22px;}

.blog-detail .item-body p { min-height: 24px; color: #666; }

.blog-detail .item-body img, .blog-detail .item-body iframe { max-width: 100%; }

.blog-detail .item-body, .blog-detail .item-body a { color: #666; }

@media screen and (max-width: 999px) { .blog-detail .item-body { padding: 44px 0; } }

@media screen and (max-width: 767px) { 
  .blog-detail .item-body { padding: 20px 0 25px; } 
  .blog-detail .item-num{float: none;}
  .pro-share{float: none;margin-top: 5px;}
}

.comments-main {background: #fff; padding-top: 59px; padding-bottom: 34px; }

.comments-main .box-form .item-top {margin-bottom: 21px; line-height: 24px; position: relative; }

@media screen and (max-width: 767px) { .comments-main .box-form .item-top { margin-bottom: 15px; } }

@media screen and (min-width: 1000px) and (max-width: 1219px) { .comments-main .box-form .item-top:before { left: -15px; right: -15px; } }

@media screen and (max-width: 999px) { .comments-main .box-form .item-top:before { left: -10px; right: -10px; } }

@media screen and (max-width: 767px) { .comments-main .box-form .item-top:before { left: -5px; right: -5px; } }

.comments-main .box-form .item-top .item1 { float: left; font-weight: bold; font-size: 16px; font-size: 1.6rem; }

.comments-main .box-form .item-top .item1 span { font-size: 14px; font-size: 1.4rem; margin-left: 16px; font-weight: normal; color: #999999;font-weight: bold;}

.comments-main .box-form .item-top .item1 a { color: #004f92; }

.comments-main .box-form .item-top .item1 a:hover { color: #0084ff; }

@media screen and (max-width: 767px) { .comments-main .box-form .item-top .item1 { font-size: 18px; font-size: 1.8rem; }
  .comments-main .box-form .item-top .item1 span { margin-left: 10px; } 
  .comments-main{padding-top: 40px;}
}

.comments-main .box-form .item-top .item2 { float: right; color: #666; font-size: 14px; font-size: 1.4rem; margin-right: 11px; }

.comments-main .box-form .item-top .item2 .iconfont { font-size: 24px; font-size: 2.4rem; vertical-align: middle; margin-right: 7px; }

@media screen and (max-width: 640px) { .comments-main .box-form .item-top .item2 { float: left; width: 100%; margin-right: 0; } }

.comments-main .box-form textarea { width: 100%; display: block; height: 80px; border: solid 1px #e7e7e7; padding: 10px; line-height: 20px; color: #333; background: #fff; -moz-box-sizing: border-box; box-sizing: border-box; }

.comments-main .box-form .tip { color: #999999; position: absolute; bottom: 8px; right: 11px; font-size: 12px;font-size: 1.2rem;}

.comments-main .box-form .item-btn { margin-top: 20px; float: right; min-width: 120px; line-height: 40px; background-color: #0084ff;color: #fff; text-align: center; font-size: 12px; font-size: 1.2rem; cursor: pointer; }

.comments-main .box-form .item-btn:hover { background-color: #067dec; }

@media screen and (max-width: 767px) { .comments-main .box-form .item-btn { line-height: 30px; } }

.comments-main .box-list { padding: 0 30px; padding-top: 17px; border-top: 1px dotted #e7e7e7;margin-top: 20px;}

.comments-main .box-list .list-item { padding: 21px 0 25px; border-bottom: 1px dotted #e7e7e7;}

@media screen and (max-width: 767px) { .comments-main .box-list .list-item { padding: 0 0 20px; } }

.comments-main .box-list .item-pic { float: left; width: 50px; height: 50px; border-radius: 50%; overflow: hidden; margin-right: 14px; }

.comments-main .box-list .item-pic img { display: block; width: 100%; height: 100%; border-radius: 50%; }

@media screen and (max-width: 767px) { .comments-main .box-list .item-pic { width: 35px; height: 35px; margin-right: 10px; } }

.comments-main .box-list .item-body { overflow: hidden; }

.comments-main .box-list .item-title { font-weight: bold; line-height: 20px;}

@media screen and (max-width: 767px) { .comments-main .box-list .item-title { margin-top: 5px; } }

.comments-main .box-list .item-desc { margin-top: 4px; line-height: 20px; color: #333333; }

.comments-main .box-list .item-desc a { display: inline; color: #0084ff; }

.comments-main .box-list .item-desc a:hover { color: #333; }

@media screen and (max-width: 767px) { .comments-main .box-list .item-desc { margin-top: 10px; } }

.comments-main .box-list .item-date { color: #999999; margin-top: 5px; line-height: 20px; font-size: 12px;font-size: 1.2rem;}

@media screen and (max-width: 767px) { .comments-main .box-list .item-date { margin-top: 10px; } }

.m-oths .list-item { width: 49%; -moz-box-sizing: border-box; box-sizing: border-box; font-size: 0; line-height: 24px;text-align: left;}

@media screen and (max-width: 767px) { .m-oths .list-item { width: 100%; padding: 3px 5px; } }


.m-oths span { float: left; width: 20px;height: 20px;line-height: 20px;border-radius: 50%;border: 1px solid #666666;text-align: center;}
.m-oths .list-item.item1 span{ float: left;margin-right: 12px;}
.m-oths .list-item.item2 span{ float: right;margin-left: 12px;}
.m-oths .list-item.item2{text-align: right;}
.m-oths span i{font-size: 12px;font-size: 1.2rem;}

.m-oths p { display: block; overflow: hidden; font-size: 14px; font-size: 1.4rem; }

.m-oths p, .m-oths p a { color: #666; }

.m-oths p a:hover { color: #0084ff; }

.comments-main .page { margin-top: 10px; }

.relative { position: relative; }

.comments{font-weight: bold;color: #999999;margin-bottom: 2px;}

@media screen and (max-width: 999px) { .m-oths .list-item{width: 100%;}
.m-oths .list-item.item2{text-align: left;}
.m-oths .list-item.item2 span{float: left;margin-left: 0;margin-right: 12px;}
}
@media screen and (max-width: 767px) { .blog-detail { padding-left: 15px; padding-right: 15px; }
  .comments-main .box-form .item-top { padding-left: 15px; padding-right: 15px; }
  .comments-main .box-form form { padding-left: 15px; padding-right: 15px; }
  .comments-main .box-list { padding-left: 15px; padding-right: 15px; } }

/*-------------5-blog-detail end-----------------------------------------------------------*/

.user-info{border: 1px solid #e7e7e7;text-align: center;padding-top: 68px;padding-bottom: 120px;}
.user-info .item-img{border-radius: 50%;box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.1);width: 110px;height: 110px;margin: 0 auto;}
.user-info .item-img img{max-width: 100%;}
.user-info .item-name{font-size:16px;font-size:1.6rem;line-height: 30px;font-weight: bold;margin-top: 11px;}

@media screen and (max-width:999px) {
  .user-info{padding-top: 40px;padding-bottom: 60px;}
  .bottom{padding-top: 35px;padding-bottom: 35px;}
}
@media screen and (max-width:767px) {
  .user-info{padding-bottom: 40px;}
}


/************* cover *************/
.cover { background: rgba(0, 0, 0, 0.3); position: fixed; top: 0; left: 0; bottom: 0; right: 0; z-index: 99999999999999999; display: none; }

.covern { background: #e9e9e9; width: 421px; margin: 0 auto; position: relative; padding-top: 30px; padding-bottom: 30px; top: 150px; }

.guanbi { position: absolute; top: 10px; right: 10px; cursor: pointer; }

.covernr { padding-left: 17px; padding-right: 28px; }

.tint, .tint2 { margin-bottom: 12px; }

.tint input { padding-left: 24px; color: #666666; height: 38px; line-height: 38px; width: 344px; background: #fff url(../images/xing2.png) no-repeat 11px center; }

.tint textarea { padding-left: 24px; color: #666666; height: 100px; line-height: 38px; width: 344px; background: #fff url(../images/xing2.png) no-repeat 11px 17px; }

.tint2 input { padding-left: 11px; color: #666666; height: 38px; line-height: 38px; width: 332px; background: #fff; width: 118px; }

.tint3 input { width: 130px; height: 38px; line-height: 38px; text-align: center; background: #0084ff; color: #fff; text-transform: uppercase; cursor: pointer; }

.tyzm { cursor: pointer; margin-left: 10px; }

@media screen and (max-width: 999px) { .covern { top: 100px; } }

@media screen and (max-width: 639px) { .covern { width: 90%; top: 55px; }
  .covernr { padding-left: 15px; padding-right: 15px; }
  .tint input { width: -moz-calc(100% - 24px); width: calc(100% - 24px); }
  .tint textarea { width: -moz-calc(100% - 24px); width: calc(100% - 24px); } }

@media screen and (max-width: 500px) { .covern { width: 98%; } }

@media screen and (max-width: 450px) { .tint2 { float: none; }
  .tint2 input { width: -moz-calc(100% - 11px); width: calc(100% - 11px); }
  .tyzm { margin-left: 0px; }
  .tint textarea { height: 80px; } }


/***********  xiala  ***********/
.yiji{position: absolute;z-index: 9999;width: 196px;background: #fff;top: -9999px;left: 50%;margin-left: -98px;}
.yiji li{position: relative;text-align: left;}
.yiji li a{display: block;color: #333;line-height: 28px;padding-top: 5px;padding-bottom: 5px;padding-left: 25px;padding-right: 10px;font-size:14px;font-size:1.4rem;}
.yiji li:hover>a{background:#0084ff;color: #fff;}

.erji{position: absolute;left: 100%;top: -999px;width: 196px;background: #fff;}
.navlist>li:hover .yiji{top: 100%;}
.yiji>li:hover .erji{top: 0px;}

@media screen and (max-width:999px) {
    .yiji{display: none;}
}

.hdtop{position: fixed;right: 20px;bottom: -100px;transition: 1s;cursor: pointer;z-index: 99999;}

/*********************fangdaxiaoguo**********************/
#baguetteBox-overlay { display: none; opacity: 0; position: fixed; overflow: hidden; top: 0; left: 0; width: 100%; height: 100%; background-color: #222; background-color: rgba(0, 0, 0, 0.8); -webkit-transition: opacity .5s ease; -o-transition: opacity .5s ease; -moz-transition: opacity .5s ease; transition: opacity .5s ease; z-index: 99999999999999999999999999999999999999; }

#baguetteBox-overlay.visible { opacity: 1; }

#baguetteBox-overlay .full-image { display: inline-block; position: relative; width: 100%; height: 100%; text-align: center; }

#baguetteBox-overlay .full-image figure { display: inline; margin: 0; height: 100%; }

#baguetteBox-overlay .full-image img { display: inline-block; width: auto; height: auto; max-height: 100%; max-width: 100%; vertical-align: middle; -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }

#baguetteBox-overlay .full-image figcaption { display: block; position: absolute; bottom: 0; width: 100%; text-align: center; line-height: 1.8; color: #ccc; background-color: #000; background-color: rgba(0, 0, 0, 0.6); font-family: Verdana, Geneva, sans-serif; }

#baguetteBox-overlay .full-image:before { content: ""; display: inline-block; height: 50%; width: 1px; margin-right: -1px; }

#baguetteBox-slider { position: absolute; left: 0; top: 0; height: 100%; width: 100%; white-space: nowrap; -webkit-transition: left .4s ease,-webkit-transform .4s ease; transition: left .4s ease,-moz-transform .4s ease; transition: left .4s ease,-webkit-transform .4s ease; -o-transition: left .4s ease,-o-transform .4s ease; -moz-transition: left .4s ease,transform .4s ease,-moz-transform .4s ease; transition: left .4s ease,transform .4s ease; transition: left .4s ease,transform .4s ease,-webkit-transform .4s ease,-moz-transform .4s ease,-o-transform .4s ease; }

#baguetteBox-slider.bounce-from-right { -webkit-animation: bounceFromRight .4s ease-out; -moz-animation: bounceFromRight .4s ease-out; animation: bounceFromRight .4s ease-out; }

#baguetteBox-slider.bounce-from-left { -webkit-animation: bounceFromLeft .4s ease-out; -moz-animation: bounceFromLeft .4s ease-out; animation: bounceFromLeft .4s ease-out; }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button { top: 50%; top: -webkit-calc(50% - 30px); top: -moz-calc(50% - 30px); top: calc(50% - 30px); width: 44px; height: 60px; }

.baguetteBox-button { position: absolute; cursor: pointer; outline: 0; padding: 0; margin: 0; border: 0; border-radius: 15%; background-color: #323232; background-color: rgba(50, 50, 50, 0.5); color: #ddd; font: 1.6em sans-serif; -webkit-transition: background-color .4s ease; -o-transition: background-color .4s ease; -moz-transition: background-color .4s ease; transition: background-color .4s ease; }

.baguetteBox-button:hover { background-color: rgba(50, 50, 50, 0.9); }

.baguetteBox-button#next-button { right: 2%; }

.baguetteBox-button#previous-button { left: 2%; }

.baguetteBox-button#close-button { top: 20px; right: 2%; right: -webkit-calc(2% + 6px); right: -moz-calc(2% + 6px); right: calc(2% + 6px); width: 30px; height: 30px; }

.baguetteBox-button svg { position: absolute; left: 0; top: 0; }

.spinner { width: 40px; height: 40px; display: inline-block; position: absolute; top: 50%; left: 50%; margin-top: -20px; margin-left: -20px; }

.double-bounce1, .double-bounce2 { width: 100%; height: 100%; border-radius: 50%; background-color: #fff; opacity: .6; position: absolute; top: 0; left: 0; -webkit-animation: bounce 2s infinite ease-in-out; -moz-animation: bounce 2s infinite ease-in-out; animation: bounce 2s infinite ease-in-out; }

.double-bounce2 { -webkit-animation-delay: -1s; -moz-animation-delay: -1s; animation-delay: -1s; }

@-webkit-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1); } }

@keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }





